import createSanityClient from '@sanity/client';

const options = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
  token: process.env.GATSBY_READ_TOKEN,
  useCdn: false,
  ignoreBrowserTokenWarning: true,
};

export const sanityClient = createSanityClient(options);

import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '@components/layout';

// Hooks Querys & Context
import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

import { PageMeta } from '@components/pageMeta';
import ModuleZone from '~moduleZone';

const ModularPage = ({ preview = false, data, previewDoc }) => {
  const page = preview ? previewDoc : data && data.page;

  const { modules, config, seo, pageBanner } = page || {};

  return (
    <Layout {...config} pageBanner={pageBanner}>
      <PageMeta {...seo} />
      {modules && <ModuleZone {...modules} />}
    </Layout>
  );
};

export default ModularPage;

export const pageQuery = graphql`
  query modularPageQuery($slug: String!) {
    page: sanityModularPage(slug: { current: { eq: $slug } }) {
      title
      seo {
        ...SEO
      }
      config {
        ...PageConfig
      }
      pageBanner {
        key: _key
        _type
        headline: _rawHeadline(resolveReferences: { maxDepth: 10 })
        bgImage {
          ...ImageWithPreview
        }
        layout
        border
      }
      modules {
        ...PageModules
      }
    }
  }
`;

import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@components/layout';
import PortableTextBlock from '@components/sanity/portableTextBlock';
import PostCard from '@components/blog/postCard';

const BlogPost = ({ preview = false, data, previewDoc }) => {
  const page = preview ? previewDoc : data && data.page;
  const { relatedPosts } = data || {};
  return (
    <Layout>
      <div className="grid grid-cols-12 px-gutter">
        <div className="col-start-1 col-end-12 lg:col-end-5">
          <h1 className="isH1 font-serif lg:sticky top-header">{page?.title}</h1>
        </div>
        <div className="col-start-1 col-end-12 lg:col-start-6">
          {page?.post && <PortableTextBlock text={page?.post} />}
        </div>
      </div>
      <div className="px-gutter grid grid-cols-1 gap-y-4 pb-16 md:grid-cols-3 md:gap-4 md:items-start lg:mt-16 lg:gap-6">
        {relatedPosts?.nodes.map((post) => (
          <PostCard {...post} />
        ))}
      </div>
    </Layout>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query postQuery($slug: String!, $created: Date!) {
    page: sanityBlogPost(slug: { current: { eq: $slug } }) {
      title
      seo {
        ...SEO
      }
      post: _rawPost(resolveReferences: { maxDepth: 10 })
    }
    relatedPosts: allSanityBlogPost(
      sort: { order: DESC, fields: _createdAt }
      limit: 2
      filter: { _createdAt: { gte: $created } }
    ) {
      nodes {
        key: _key
        title
        slug {
          current
        }
      }
    }
  }
`;
